
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../../translations/cart-details/confirm-payment-failuare-dialog.translations";
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../../state/app.state";
import {Subscription} from "rxjs";
import {selectedLanguage} from "../../../../shared/core/shared.selectors";
import {CommonService} from "../../../../shared/services/common.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-order-confirm-failed-dialog',
  templateUrl: './order-confirm-failed-dialog.component.html',
  styleUrls: ['./order-confirm-failed-dialog.component.css']
})
export class OrderConfirmFailedDialogComponent implements OnInit {
language: number = 1;
  translations: any = Translations;
  errorMessage: string = "";

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });
  data: any;

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.translations = Translations[this.language];
    if(this.data.message.includes('unable to authenticate')||this.data.message.includes('支払い方法を認証できません。別の支払い方法を選択して、もう一度お試しください。')){
      this.errorMessage = this.translations.stripePaymentFailed;
    }
    else if(this.data.message.includes('Payment cancelled')){
      this.errorMessage = this.translations.paymentDeclined;
    }
    else if(this.data.message.includes('timeout')){
      this.errorMessage = this.translations.stripePaymentFailedTimeout;
    }
    else if(this.data.message.includes('internet')||this.data.message.includes('ペイメントプロバイダーへの接続中に問題が発生しました。インターネット接続を確認してください。')){
      this.errorMessage = this.translations. stripePaymentFailedNoInternet;
    }
    else if(this.data.message.includes('insufficient funds')||this.data.message.includes('カード残高が不足しています。別のカードをお試しください。')){
      this.errorMessage = this.translations.stripePaymentFailedInsufficientFunds;
    }
    else if(this.data.message.includes('card was declined')){
      this.errorMessage = this.translations.cardDeclined;
    }
    else{
      this.errorMessage = this.translations.stripePaymentFailedUnknownError;
    }
  }

  backToHome() {
    this.commonService.closeDialog();
    this.router.navigate(['/'])
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

}
