interface ConfirmPaymentFailuareDialogTranslations {
    backToHomeButtonLabel: string,
    failedMessage: string,
    paymentDeclined:string,
    stripePaymentFailed:string,
    stripePaymentFailedNoInternet:string,
    stripePaymentFailedTimeout:string,
    stripePaymentFailedInsufficientFunds:string,
    stripePaymentFailedUnknownError:string,
    processingPaymentMessage:string,
    cardDeclined:string,
    error:string,

  }

  const en: ConfirmPaymentFailuareDialogTranslations = {
    backToHomeButtonLabel: 'Back to Home',
    failedMessage: 'Your Order has been Failed.',
    error:'Error',
    paymentDeclined: 'Payment declined!',
    stripePaymentFailed: 'Payment failed due to unable to authenticate your payment method.',
    stripePaymentFailedNoInternet: 'Payment failed due to no internet.',
    stripePaymentFailedTimeout: 'Payment failed due to timeout.',
    stripePaymentFailedInsufficientFunds: 'Payment failed - Your card was declined due to insufficient funds.',
    stripePaymentFailedUnknownError: 'Payment failed due to an unknown error.',
    processingPaymentMessage: 'Your payment is being processed. Do not close the window or press the back button.',
    cardDeclined: 'Payment failed - Your card was declined.'
  }

  const ja: ConfirmPaymentFailuareDialogTranslations = {
    backToHomeButtonLabel: 'ホームに戻る',
    failedMessage: '注文が失敗しました',
    error:'エラー',
    paymentDeclined: '支払いが拒否されました',
    stripePaymentFailed: '支払い方法の認証に失敗したため、支払いができませんでした',
    stripePaymentFailedNoInternet: 'インターネット接続がないため、支払いができませんでした',
    stripePaymentFailedTimeout: 'タイムアウトのため、支払いができませんでした',
    stripePaymentFailedInsufficientFunds: '支払いに失敗しました - 残高不足のため、カードが拒否されました',
    stripePaymentFailedUnknownError: '不明なエラーにより、支払いができませんでした',
    processingPaymentMessage: '支払いを処理中です。このウィンドウを閉じたり、戻るボタンを押したりしないでください',
    cardDeclined: '支払いに失敗しました - カードが拒否されました'
  }

  export const Translations = {
    1: en,
    2: ja
}
